<template>
    <KTLoader v-if="loaderEnabled" :logo="loaderLogo"></KTLoader>

    <!-- begin:: Body -->
    <div class="page d-flex flex-row flex-column-fluid">
        <!-- begin:: Aside Left -->
        <KTAside v-if="asideEnabled" :lightLogo="themeLightLogo" :darkLogo="themeDarkLogo"></KTAside>
        <!-- end:: Aside Left -->

        <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
            <KTHeader :title="pageTitle"></KTHeader>

            <!-- begin:: Content -->
            <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
                <!-- begin:: Content Head -->
                <KTToolbar v-if="subheaderDisplay" :breadcrumbs="breadcrumbs" :title="pageTitle"/>
                <!-- end:: Content Head -->

                <!-- begin:: Content Body -->
                <div class="post d-flex flex-column-fluid">
                    <div id="kt_content_container" :class="{ 'container-fluid': contentWidthFluid,'container-xxl': !contentWidthFluid}">
                        <router-view v-if="showPage"/>
                    </div>
                </div>
                <!-- end:: Content Body -->
            </div>
            <!-- end:: Content -->
            <KTFooter></KTFooter>
        </div>
    </div>
    <!-- end:: Body -->
    <KTScrollTop></KTScrollTop>
    <KTDrawerMessenger></KTDrawerMessenger>
</template>

<script>
import KTAside from "@/layouts/aside/Aside.vue";
import KTHeader from "@/layouts/header/Header.vue";
import KTFooter from "@/layouts/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layouts/toolbar/Toolbar.vue";
import KTScrollTop from "@/layouts/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import KTDrawerMessenger from "@/layouts/extras/DrawerMessenger.vue";
import {Actions} from "@/store/enum/StoreEnums";
import {removeModalBackdrop} from "@/core/helpers/dom";
import {
    MenuComponent,
    DrawerComponent,
    ScrollComponent,
} from "@/assets/js/components/index";
import {
    toolbarDisplay,
    loaderEnabled,
    contentWidthFluid,
    loaderLogo,
    asideEnabled,
    subheaderDisplay,
    themeLightLogo,
    themeDarkLogo,
} from "@/core/helpers/config";

export default {
    name: 'default',
    data() {
        return {
            toolbarDisplay,
            loaderEnabled,
            contentWidthFluid,
            loaderLogo,
            asideEnabled,
            subheaderDisplay,
            themeLightLogo,
            themeDarkLogo,
        }
    },
    components: {
        KTAside,
        KTHeader,
        KTFooter,
        KTToolbar,
        KTScrollTop,
        KTDrawerMessenger,
        KTLoader
    },
    created() {
        // show page loading
        this.$store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

        // initialize html element classes
        HtmlClass.init();
    },
    computed: {
        pageTitle() {
            return this.$store.getters.pageTitle;
        },
        breadcrumbs() {
            return this.$store.getters.pageBreadcrumbPath;
        },
        showPage(){
            return this.$root.currentUser.id;
        }
    },
    mounted() {
        DrawerComponent.bootstrap();
        ScrollComponent.bootstrap();
        DrawerComponent.updateAll();
        ScrollComponent.updateAll();

        setTimeout(() => {
            // Remove page loader after some time
            this.$store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        }, 500);
    },
    watch: {
        '$route.path': function () {
            MenuComponent.hideDropdowns(undefined);

            DrawerComponent.hideAll();

            //check if current user is authenticated
            if (!this.$store.getters.isUserAuthenticated) {
                this.$router.push({name: "login"});
            }

            removeModalBackdrop();
        }
    },
}
</script>